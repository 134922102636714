.o-header {
  .-advi-logo-container {
    width: 200px;
    height: 100px;
    background: url('/img/Logo_background.png') no-repeat center center;
    display: flex;
    justify-content: center;
    .-advi-logo {
      max-width: 70%; } } }
.c-pink-h2 {
  font-weight: 300;
  font-size: 20px; }
.swiper-container {
  min-height: 105px;
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #a5055B; } }
.c-logo {
  width: auto;
  height: 30px; }
.c-preference {
  div {
    font-size: 12px; } }
.dovidnik-el {
  display: flex;
  flex-direction: column;
  flex: 0 0 50% !important;
  flex-wrap: wrap;
  padding: 5px 5px 30px 5px;
  text-decoration: none;
  .img-box {
    width: 100%;
    max-height: 160px;
    height: 160px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  .dovidnik-name {
    text-transform: uppercase;
    margin-top: 10px;
    position: relative;
    color: #777;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important; } }
.dovidnik-content {
  color: #999;
  font-size: 16px;
  line-height: 1.3;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  img {
    width: 350px;
    float: left;
    margin-bottom: 20px;
    margin-right: 40px; }
  h3 {
    font-size: 18px;
    font-family: BebasNeueCyrillic;
    font-weight: normal;
    padding-left: 25px;
    strong {
      font-size: 20px;
      font-family: BebasNeueCyrillic;
      font-weight: normal !important; } } }
.dropdown-item {
  position: relative;
  img {
    position: absolute;
    left: 15px;
    height: 35px; } }
@media (max-width: 350px) {
  .dovidnik-el {
    flex: 0 0 100% !important; } }
