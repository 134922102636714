// Переопределение дефолтных значений переменных Bootstrap 4 и определение своих
@import "variables";

// Подключение нужных SCSS исходников Bootstrap 4
@import "../libs/bootstrap/scss/functions";
@import "../libs/bootstrap/scss/variables";
@import "../libs/bootstrap/scss/mixins";
@import "../libs/bootstrap/scss/reboot";
@import "../libs/bootstrap/scss/grid";
@import "../libs/bootstrap/scss/tables";
@import "../libs/bootstrap/scss/forms";
@import "../libs/bootstrap/scss/buttons";
@import "../libs/bootstrap/scss/transitions";
@import "../libs/bootstrap/scss/dropdown";
@import "../libs/bootstrap/scss/input-group";
@import "../libs/bootstrap/scss/nav";
@import "../libs/bootstrap/scss/navbar";
@import "../libs/bootstrap/scss/card";
@import "../libs/bootstrap/scss/pagination";
@import "../libs/bootstrap/scss/badge";
@import "../libs/bootstrap/scss/alert";
@import "../libs/bootstrap/scss/close";
@import "../libs/bootstrap/scss/utilities";
@import "../libs/bootstrap/scss/images";
@import "../libs/bootstrap/scss/modal";
@import "../libs/bootstrap/scss/media";
@import "../libs/bootstrap/scss/carousel";

@import "../libs/bower_components/swiper/dist/css/swiper.min.css";
@import "../libs/bower_components/jquery-ui/themes/base/jquery-ui.min.css";

// Подключение своих SCSS файлов

@import "swipesite.css";
@import "dwsbtn.css";
@import "animate.css";
@import "style_old.css";
@import "firstscreen_styles";
